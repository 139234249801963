import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Apple from "../images/favicon/apple-touch-icon.png"
import BiggerFav from "../images/favicon/favicon-32x32.png"
import SmallerFav from "../images/favicon/favicon-16x16.png"
import ASmallerFav from "../images/favicon/android-chrome-192x192.png"
import ABiggerFav from "../images/favicon/android-chrome-512x512.png"

const SEO = ( {language} ) => {

  const esp = `Elefante es una productora audiovisual y estudio creativo que produce contenido documental y de ficción para cine, TV y medios digitales, y ofrece servicios de producción a proyectos nacionales y extranjeros producidos en México.`
  
  const eng = `Elefante is an audiovisual production company and creative studio focused in producing documentary and fiction content for film, TV and digital media, and offers a production services platform for national and foreign projects developed in Mexico.`

  let desc = language === "ES" ? esp : eng

  let langA = language === "ES" ? "es" : "en"

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return [

    <Helmet
      htmlAttributes={{
        lang: langA,
      }}
      title={site.siteMetadata.title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: desc,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: desc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: desc,
        },
      ]}
      link={[
        { 
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: `${Apple}`,
        },
        { 
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: `${BiggerFav}`
        },
        { 
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: `${SmallerFav}`
        },
        { 
          rel: "icon",
          type: "image/png",
          sizes: "192x192",
          href: `${ASmallerFav}`
        },
        { 
          rel: "icon",
          type: "image/png",
          sizes: "512x512",
          href: `${ABiggerFav}`
        },
      ]}
    />

  ]

}

export default SEO
