import React, {useState, useEffect} from "react"
import { store } from "../store"
import { connect } from "react-redux"
import { getHeight, getWidth } from "../actions"
import Header from "./header"
import MobileHeader from "./mobileHeader"
import BlackHeader from "../components/blackHeader"
import BlackMobileHeader from "../components/blackMobileHeader"
import { globalHistory } from "@reach/router"
import SEO from "../components/seo"

const HeaderDecider  = (props) => {

    const path = globalHistory.location.pathname

    //this comp mounts and sets the window width and window height in the store; it also listens for window
    //resize and dispatches accordingly
    //breakpoints: 576px, 768px, 992px

    const [windowSize, changeSize] = useState({})

    //on mount
    useEffect(() => {

        store.dispatch(getWidth(window.innerWidth))
        store.dispatch(getHeight(window.innerHeight))
        changeSize({width: window.innerWidth, height: window.innerHeight})

    }, [])

    //on window resize
    useEffect(()=>{ 

        const handleResize = (e) => {
            e.preventDefault()
            changeSize({width: window.innerWidth, height: window.innerHeight})
            store.dispatch(getWidth(window.innerWidth))
            store.dispatch(getHeight(window.innerHeight))
        }

        window.addEventListener(`resize`, handleResize)
        return () => {window.removeEventListener(`resize`, handleResize)}

    }, [windowSize])

    let header;

    if (windowSize.width <= 768) {

        if (path === "/journal" || path === "/journal/" || path === "/editorial" || path === "/editorial/") {

            header = <BlackMobileHeader key={`mobile`} />

        } else {

            header = <MobileHeader key={`mobile`} />

        }

    } else if (windowSize.width > 768) {

        if (path === "/journal" || path === "/journal/" || path === "/editorial" || path === "/editorial/") {

            header = <BlackHeader key={`web`} />

        } else {

            header = <Header key={`web`} />

        }

    }

    return [

        <SEO language={props.lang} />,

        <div key={`header decider`}>{header}</div>
        
    ]

}

const mapStateToProps = (state) => {
    return {
        lang: state.lang,
        coords: state.coords,
        width: state.width,
        height: state.height
    }
  }
  
export default connect(mapStateToProps)(HeaderDecider)