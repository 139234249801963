import React, { useRef, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"

const MobileModal = props => {

    const showHide = props.display ? 'block' : 'none'

    useEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
    
        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const modalStyle = {
        
        color: `#D9D9D9`,
        background: `black`,
        minHeight: props.height,
        display: showHide,
        position: `relative`,
        paddingLeft: `30px`,
        zIndex: 3,
        position: `fixed`,
        top: 0,
        left: 0,
        width: `100%`
    }

    const divStyle = {
        paddingTop: `150px`,
        position: `relative`,
    }

    const linkStyle = {
        textDecoration: `none`,
        color: `#D9D9D9`
    }

    //<div><Link style={linkStyle} to="/production" activeStyle={{ color: "white" }}>{props.headerLang.op4}</Link></div><br />

    return ( 
        <div className="hi" style={modalStyle}>
            
            <div style={divStyle}>

                <div><Link style={linkStyle} to="/about" activeStyle={{ color: "white" }}>{props.headerLang.op1}</Link></div><br />
                <div><Link style={linkStyle} to="/work" activeStyle={{ color: "white" }}>{props.headerLang.op2}</Link></div><br />
                <div><Link style={linkStyle} to="/films" activeStyle={{ color: "white" }}>{props.headerLang.op3}</Link></div><br />
                <div><Link style={linkStyle} to="/editorial" activeStyle={{ color: "white" }}>{props.headerLang.op5}</Link></div><br />
                <div><Link style={linkStyle} to="/journal" activeStyle={{ color: "white" }}>{props.headerLang.op6}</Link></div><br />
                <div><Link style={linkStyle} to="/contact" activeStyle={{ color: "white" }}>{props.headerLang.op7}</Link></div><br />
                <div className="pointer"><span style={props.lang === "ES" ? {color: `white`} : {color: `#D9D9D9`}} onClick={props.langButt}>ES</span> | <span style={props.lang === "EN" ? {color: `white`} : {color: `#D9D9D9`}} onClick={props.langButt}>EN</span></div>

            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang,
    }
  }
  
export default connect(mapStateToProps)(MobileModal)