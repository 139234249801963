import { Link, navigate } from "gatsby"
import React, { useRef, useLayoutEffect } from "react"
import Logo from "../images/Elefante_Logo_Blanco.png"
import Mute from "../images/mute.png"
import Sound from "../images/sound.png"
import { store } from "../store"
import { setLang, aboutCoords, toggleMute } from "../actions"
import { connect } from "react-redux"

const Header = ( props ) => { 

  const langButt = (e) => {
    e.preventDefault()
    const string = e.target.innerHTML
    store.dispatch(setLang(string))
  }

  const aboutRef = useRef("about")
  const contactRef = useRef("contact")

  //on component mount hook
  useLayoutEffect(()=>{

    const aboutRect = aboutRef.current.getBoundingClientRect()
    const contactRect = contactRef.current.getBoundingClientRect()

    let mama = {...props.coords, top: aboutRect.top + 60, left: aboutRect.left, right: window.innerWidth - contactRect.right, opacity: 1}

    store.dispatch(aboutCoords(mama))

  }, [])

  //on lang change hook
  useLayoutEffect(()=>{

    const aboutRect = aboutRef.current.getBoundingClientRect()
    const contactRect = contactRef.current.getBoundingClientRect()

    let mama = {...props.coords, top: aboutRect.top + 60, left: aboutRect.left, right: window.innerWidth - contactRect.right, opacity: 1}

    store.dispatch(aboutCoords(mama))

  }, [props.lang])

  //on window resize hook
  useLayoutEffect(()=>{

    const handleResize = (e) => {
      e.preventDefault()
      const aboutRect = aboutRef.current.getBoundingClientRect()
      const contactRect = contactRef.current.getBoundingClientRect()

      let mama = {...props.coords, top: aboutRect.top + 60, left: aboutRect.left, right: window.innerWidth - contactRect.right, opacity: 1}

      store.dispatch(aboutCoords(mama))
  }

    window.addEventListener(`resize`, handleResize)
    return () => { window.removeEventListener(`resize`, handleResize) }

  })

  const soundToggle = (event) => {
    event.preventDefault()

    if (props.mute === true) {
      store.dispatch(toggleMute(false))
    } else {
      store.dispatch(toggleMute(true))
    }

  }

  let on;

  let language;

  if (props.mute) {
    on = <span onClick={soundToggle}><img className="pointer" style={{color: "white"}} width="15px" height="auto" src={Mute} alt="elefante" /></span>
  } else {
    on = <span onClick={soundToggle}><img className="pointer" style={{color: "white"}} width="15px" height="auto" src={Sound} alt="elefante" /></span>
  }

  if (props.lang === "ES") {
    
    language = {
      op1: "Acerca de",
      op2: "Videoclips",
      op3: "Cine/TV",
      //op4: "Servicios de producción",
      op5: "Editorial",
      op6: "Bitácora",
      op7: "Contacto"
    }
  } else if (props.lang === "EN") {
    
    language = {
      op1: "About",
      op2: "Music Videos",
      op3: "Film/TV",
      //op4: "Production Services",
      op5: "Editorial",
      op6: "Journal",
      op7: "Contact"
    }
  }

  const goHome = (e) => {
    e.preventDefault()
    navigate("/")
  }

  //<div><Link to="/production" activeStyle={{ color: "white" }}>{language.op4}</Link></div> 

  return ( 
  
  <nav>

    <div className="header">

      <img onClick={goHome} className="navLogo" width="130px" height="auto" src={Logo} alt="elefante"></img>
      <div ref={aboutRef}><Link to="/about" activeStyle={{ color: "white" }}>{language.op1}</Link></div>
      <div><Link to="/work" activeStyle={{ color: "white" }}>{language.op2}</Link></div>
      <div><Link to="/films" activeStyle={{ color: "white" }}>{language.op3}</Link></div>
      <div><Link to="/editorial" activeStyle={{ color: "white" }}>{language.op5}</Link></div>
      <div><Link to="/journal" activeStyle={{ color: "white" }}>{language.op6}</Link></div>
      <div ref={contactRef}><Link to="/contact" activeStyle={{ color: "white" }}>{language.op7}</Link></div>
      <div>{on}</div>
      <div className="pointer"><span style={props.lang == "ES" ? {color: `white`}: {color: `#D9D9D9`}} onClick={langButt}>ES</span> | <span style={props.lang == "EN" ? {color: `white`}: {color: `#D9D9D9`}} onClick={langButt}>EN</span></div>
      
    </div>

  </nav>

  )

}

const mapStateToProps = (state) => {
  return {
      lang: state.lang,
      coords: state.coords,
      mute: state.mute
  }
}

export default connect(mapStateToProps)(Header)
