export function setLang(lang) {
    return {
        type: "SET_LANGUAGE",
        lang: lang
    }
}

export function aboutCoords(coords) {
    return {
        type: "ABOUT_COORDS",
        coords: coords
    }
}

export function getWidth(width) {
    return {
        type: "WINDOW_WIDTH",
        width: width
    }
}

export function getHeight(height) {
    return {
        type: "WINDOW_HEIGHT",
        height: height
    }
}

export function toggleMute(mute) {
    return {
        type: "TOGGLE_MUTE",
        mute: mute
    }
}