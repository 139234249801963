import React, { useState, useEffect, useRef } from "react"
import Logo from "../images/Elefante_Logo_Negro.png"
import { navigate } from "gatsby"
import { store } from "../store"
import { setLang } from "../actions"
import { connect } from "react-redux"
import MobileModal from "./mobileModal"
import Close from "../images/close.png"
import Menu from "../images/bmenu.png"

const MobileHeader = props => {

    const [modal, display] = useState(false)

    const langButt = (e) => {
        e.preventDefault()
        const string = e.target.innerHTML
        store.dispatch(setLang(string))
    }

    const modalToggle = event => {

        event.preventDefault()

        if (modal) {
            display(false)
        } else {
            display(true)
        }
    
    }

    let language;

    if (props.lang === "ES") {
    
        language = {
          op1: "Acerca de",
          op2: "Videoclips",
          op3: "Cine/TV",
          //op4: "Servicios de producción",
          op5: "Editorial",
          op6: "Bitácora",
          op7: "Contacto"
        }
      } else if (props.lang === "EN") {
        
        language = {
          op1: "About",
          op2: "Music Videos",
          op3: "Film/TV",
          //op4: "Production Services",
          op5: "Editorial",
          op6: "Journal",
          op7: "Contact"
        }
      }

    const goHome = e => {
        e.preventDefault()
        navigate("/")
    }

    return [  
        <nav>
      
          <div className="header">
      
            <img onClick={goHome} className="navLogo" width="130px" height="auto" src={Logo} alt="elefante"></img>

            <span onClick={modalToggle}>{modal ? <img className="pointer" width="15px" height="auto" src={Close} alt="close"></img> : <img className="pointer" style={{color: "white"}} width="35px" height="auto" src={Menu} alt="elefante" />}</span>
            
          </div>
      
        </nav>,

        <MobileModal display={modal} headerLang={language} langButt={langButt} />,
      
    ]

}

const mapStateToProps = (state) => {
    return {
        lang: state.lang,
        coords: state.coords,
    }
  }
  
export default connect(mapStateToProps)(MobileHeader)